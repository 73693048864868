/* eslint-disable */
const en = {
  translation: {
    common: {
      untitled: 'Untitled',
    },
    general: {
      error: 'There was an error...',
    },
    validation: {
      short: 'Must be at least ${min} characters!',
      long: 'Must be at most ${max} characters!',
      email: 'Invalid email!',
      required: 'Required',
      default: 'Field is invalid',
    },
    error: {
      boundary: {
        text: 'We are sorry, but something went wrong.',
        button: 'Back to homepage',
      },
      not_found: {
        heading: 'We are sorry, page not found!',
        text: 'The page you are looking for might have been changed or it is not available anymore.',
        button: 'Back to homepage',
      },
    },
    maintenance: {
      heading: 'Maintenance',
      text: 'We are currently under maintenance, we will be back soon!',
    },
    modals: {
      note: {
        title: 'Notes',
        placeholder: 'Write your notes about this card here...',
      },
    },
    editor: {
      emojiPicker: {
        search: 'Search',
        search_no_results_1: 'Oh no!',
        search_no_results_2: 'That emoji couldn’t be found',
        pick: 'Pick an emoji…',
        add_custom: 'Add custom emoji',
        categories: {
          activity: 'Activity',
          custom: 'Custom',
          flags: 'Flags',
          foods: 'Food & Drink',
          frequent: 'Frequently used',
          nature: 'Animals & Nature',
          objects: 'Objects',
          people: 'Smileys & People',
          places: 'Travel & Places',
          search: 'Search Results',
          symbols: 'Symbols',
        },
        skins: {
          choose: 'Choose default skin tone',
          1: 'Default',
          2: 'Light',
          3: 'Medium-Light',
          4: 'Medium',
          5: 'Medium-Dark',
          6: 'Dark',
        },
      },
    },
    auth: {
      verify: {
        not: {
          title: 'Email not verified',
          text: 'Your email is not verified yet. Please check your email and verify your account.',
          button: 'Resend verification email',
        },
        sent: {
          title: 'Verification email sent',
          text: 'We sent a verification email to {{email}}. Please check your inbox and verify your account.',
          note: 'Note: If you did not receive the email, please check your spam folder.',
          error: 'Error sending email, try again later.',
        },
        expired: {
          title: 'Link expired',
          text: 'Sorry, this link expired, you can ask for a new verification link.',
          button: 'Login',
        },
        verified: {
          title: 'Email verified',
          text: 'Your email has been verified, you can now login.',
          button: 'Login',
        },
      },
      login: {
        heading: 'Welcome back',
        forgotPw: 'Forgot password?',
        incorrect: 'Incorrent email or password!',
        signUpHere: 'Sign up here.',
        button: 'Login',
      },
      register: {
        name: 'Name',
        heading: "It's a beautiful day to start learning...",
        agreeTerms: 'By registering, I agree to Terms of Service and Privacy Policy.',
        button: 'Register',
        passwordConfirmation: 'Password again',
        passwordConfirmationError: 'Passwords must match',

        denied: {
          title: 'Registration closed',
          text: 'We are currently full...',
          button: 'Contact',
        },

        success: {
          title: 'Success',
          text: 'You are successfully signed up.',
          button: 'Login',
        },
      },
      forgot: {
        heading: 'Reset your password',
        label: 'Your email address',
        back: 'Back to login',
        button: 'Send password reset email',
        sent: {
          title: 'Check your inbox',
          text: 'We sent a link to {{email}}, follow the link to create a password',
          button: 'Back to Login',
        },
      },
      reset: {
        heading: 'Create password',
        help: 'Use a strong password with at least 6 characters',

        password: 'New password',
        passwordAgain: 'New password again',
        passwordDoesntMatch: "Sorry. Password doesn't match",
        button: 'Update password',

        saved: {
          title: 'New password saved',
          text: 'You will be automatically redirected to login.',
          button: 'Login',
        },

        expired: {
          title: 'Link expired',
          text: 'Sorry, this link expired, you can ask for a new password reset link.',
          button: 'Get a new link',
        },
      },
      email: 'Email',
      password: 'Password',
      error: 'Something went wrong, please try again or contact us.',
    },
    contact: {
      heading: 'Contact us',
      name: 'Name',
      email: 'Email',
      subject: 'Subject',
      message: 'Message',
      action: 'Submit',
      success: 'We received your message, we get back to you in 24 hours!',
    },
    navbar: {
      profile: 'Profile',
      help: 'Help',
      studyProgress: 'Study progress',
      userCards: 'User cards',
      logout: 'Logout',
    },
    notifications: {
      heading: 'Notifications',
      'request-receive': 'Your team got a request from {{user}} to join.',
      'request-decline': 'Your request to join {{team}} was declined.',
      'request-accept': 'Your request to join {{team}} was acceped.',
      'request-block': 'Your request to join {{team}} was blocked.',
      'request-delete': 'Request of {{user}} to join your team was deleted.',
      'invite-decline': 'Your invite to {{user}} was declined.',
      'invite-accept': 'Your invite to {{user}} was acceped.',
      'invite-block': 'Your invite to {{user}} was blocked.',
      'invite-receive': 'You got an invite to join {{team}}.',
      'invite-delete': 'Your invite from {{team}} was deleted.',
      'team-member-kickout': '{{user}} was kicked out of your team.',
      'team-kickout': 'You where kicked out of {{team}}.',
      'team-leave': '{{user}} left your team.',
      'team-disband': 'Your team has been disbanded.',
      'team-points-reset': 'Team points reset.',
      badge: 'You got a new badge: {{badge}}.',
      'study-invite-receive': 'You got an invite to study together with {{user}}.',
      'study-invite-accept': 'Your invite to to study together with {{user}} was acceped.',
      'study-invite-decline': 'Your invite to to study together with {{user}} was declined.',
      'study-invite-revoke': '{{user}} revoked the invite to study together.',
      'study-partner-breakup': '{{user}} breaked up the study partnershio with you.',
      'study-settings-pending': 'Your partner changed the study settings. Please confirm.',
      'study-settings-accepted': 'Your partner accepted the study settings.',
      'study-settings-declined': 'Your partner declined the study settings.',
      'study-settings-cancelled': 'Your partner cancelled the study settings.',
    },
    dashboard: {
      search: 'Search',
      simulations: 'Simulations',
      learn: 'Learn',
      arena: 'Learn Together',
      statistic: 'Statisitic',
      decks: 'Decks',
    },
    membership: {
      pending: {
        title: 'Course not started',
        text: 'The course has not started yet, the start date is {{startDate}}, come back later!',
      },
      newbie: {
        title: 'Not a member',
        text: 'You are not a member of this course, you can not access it!',
        payButton: 'Become a member',
      },
      expired: {
        title: 'Membership expired',
        text: 'Your membership expired, you can not access it!',
        payButton: 'Become a member',
      },
    },
    shop: {
      success: {
        title: 'Payment successful',
        text: 'Your payment was successful.',
        button: 'Go to dashboard',
      },
      failed: {
        title: 'Payment failed',
        text: 'Your payment failed.',
        button: 'OK',
      },
      pending: {
        title: 'Payment pending',
        text: 'Payment in progress...',
        button: 'Refresh',
      },
    },
    profile: {
      heading: 'Profile',
      avatar: {
        title: 'Change Your Image',
        description: 'You can drag the picture to crop it differently.',
        upload: 'Upload image',
        change: 'Change image',
        delete: 'Delete image',
        zoom: 'Zoom',
        confirm: 'Confirm',
        cancel: 'Cancel',
        notimg: 'Selected file is not supported. Please upload only .jpeg, .png or .svg file.',
        toobig: 'Selected file is too big. Max size is 5MB.',
        allowed: 'Allowed *.jpeg, *.jpg, *.png, *.gif',
        max: 'max size of 5 MB',
      },
      basic: {
        title: 'Basic info',
        email: 'Email',
        name: 'Name',
        save: 'Save changes',
      },
      learning: {
        title: 'Learning',
        spread: {
          label: 'New card spread',
          default: 'Site default',
          first: 'First',
          distribute: 'Distribute',
          last: 'Last',
        },
      },
      password: {
        title: 'Change password',
        currentPassword: 'Current password',
        newPassword: 'New password',
        newPasswordAgain: 'New password again',
        save: 'Update password',
      },
      notifications: {
        title: 'Notifications',
        reminder: {
          primary: 'Learning Reminder',
          secondary: 'Daily reminder if you forget to learn what is due',
        },
        marketing: {
          primary: 'Updates and Offers',
          secondary: 'Discounts, special offers, new features and more',
        },
      },
      remorse: {
        title: 'Are you sure?',
        text: 'Please think about it again, we want you to succeed and if you are a lazy, you never will be a doctor!',
        confirm: 'Yes, please',
        cancel: 'Cancel',
      },
      tutor: {
        heading: 'Reset tutor',
        text: 'Here you can reset your done tutors, so you can take them again.',
        button: 'Reset tutor',
      },
      error: {
        pwIncorrect: 'Password incorrect!',
      },
      membership: {
        heading: 'Membership',
        text: 'Your membership started at {{startDate}} and ends at {{endDate}}.',
      },
    },
    simulations: {
      heading: 'Simulations',
      button: 'Results',
      create_practice: 'Create',
      favorite: {
        mark: 'Mark as favorite',
        unmark: 'Unmark as favorite',
      },
      difficulties: {
        diff_1: 'Easy',
        diff_2: 'Normal',
        diff_3: 'Medium',
        diff_4: 'Hard',
      },
      info: {
        difficulty: 'Difficulty',
        questions: 'Questions',
        score: 'Score',
        last: 'Last score',
        first: 'First score',
        max: 'Max Score',
        avg: 'Avg Score',
        start: 'Start',
        history: 'History',
        marked: 'Marked questions',
        wrong: 'Wrong questions',
        delete_practice: 'Delete practice',
      },
      unfinished: {
        questions: 'Questions',
        created_at: 'Creaated at',
        updated_at: 'Updated at',
        answers: 'Answers',
        elapsed: 'Elapsed time',
        minutes: '{{count}} minutes',
        title: 'Unfinished',
        continue: 'Continue',
        tip: 'Unfinished simulation',
      },
      config: {
        marked: 'Marked',
        wrong: 'Wrong',
        fill: 'Fill',
        submit_each_question: 'Submit each question',
      },
      shuffle: {
        label: 'Shuffle config',
        variant_0: 'Shuffle now',
        variant_1: 'Shuffle variant 1',
        variant_2: 'Shuffle variant 2',
        variant_3: 'Shuffle variant 3',
      },
      denied: {
        title: 'Access denied!',
        text: 'You dont have access to the simulations!',
        button: 'Go to dashboard',
      },
      saved: {
        title: 'Unfinished simulation',
        questions: 'Questions',
        answers: 'Answers',
        minutes: 'Minutes',
      },
      confirm: {
        unfinished: {
          title: 'Unfinished simulation',
          text: 'You have an unfinished simulation, do you want to continue?',
          confirm: 'Yes, continue',
          cancel: 'Cancel',
        },
        delete: {
          title: 'Delete simulation save',
          text: 'Are you sure you want to delete this simulation save?',
          confirm: 'Yes, delete',
          cancel: 'Cancel',
        },
        delete_practice: {
          title: 'Delete simulation',
          text: 'Are you sure you want to delete this practice simulation?',
          confirm: 'Yes, delete',
          cancel: 'Cancel',
        },
      },
      builder: {
        dialog: {
          title: 'Create a practice',
        },
        steps: {
          config: 'Configuration',
          summary: 'Summary',
          create: 'Create',
          next: 'Continue',
          back: 'Back',
          save: 'Save',
        },
        form: {
          name: 'Name',
          description: 'Description',
          topics: 'Topic',
          chapters: {
            label: 'Chapter',
            value: '{{topic}}: {{chapter}}',
          },
          difficulties: 'Difficulty',
          tags: 'Tags',
          question_filter: {
            label: 'Filter questions',
            none: 'All questions',
            flagged_failed: 'Only flagged and failed',
            unseen: 'Only unseen',
          },
          question_type: {
            label: 'Question type',
            all: 'All questions',
            simu: 'Simulation',
            practice: 'Practice',
          },
          limit: {
            total: 'Limit the amount of questions',
            topic: 'Limit the amount of questions per topic',
          },
          shuffle_topics: 'Shuffle topics',
          shuffle_questions: 'Shuffle questions',
          shuffle_options: 'Shuffle options',
          submit_each_question: 'Submit each question',
        },
        summary: {
          topics: 'Topics:',
          chapters: {
            label: 'Chapters:',
            value: '{{topic}}: {{chapter}}',
          },
          difficulties: 'Difficulties:',
          tags: {
            label: 'Tags:',
            value: '{{tag}} and {{count}} other...',
          },
          question_type: 'Question type:',
          question_filter: 'Filter questions:',
          amount: 'Amount:',
          topic_amount: '{{count}} (from {{total}} available)',
          total: 'Total:',
          total_value: '{{count}} (from {{total}} available)',
          non_selected: 'Non selected',
        },
      },
    },
    simuresults: {
      heading: 'Simulation Results',
      questions: 'Questions',
      correct: 'Correct',
      minutes: 'Minutes',
      empty: {
        title: 'No results',
        text: 'You have not done any simulations yet.',
        button: 'Simulations',
      },
    },
    simulate: {
      header: {
        questions: '{{count}} Questions',
        elapsed: 'Elapsed',
      },
      footer: {
        stop: 'Stop simulation',
        finish: 'Finish simulation',
        endview: 'Finish review',
      },
      question: {
        no: 'Question {{number}}',
        explanation: 'Explanation',
        mark: 'Mark question',
        unmark: 'Unmark question',
        reason: {
          filler: 'This is a filler question!',
          marked: 'This is a marked question!',
          wrong: 'This is a wrong question!',
        },
        submit: 'Submit question',
      },
      leaveConfirm: 'Are you sure you want to leave?',
      stats: {
        unanswered: 'Unanswered',
        correct: 'Correct',
        wrong: 'Wrong',
        time: 'Time',
        score: 'Score',
        minutes: '{{minutes}} Min',
      },
      chart: {
        title: 'Question Difficulty Distribution',
        correct: 'Correct',
        wrong: 'Wrong',
        questions: '{{count}} questions',
      },
      report: {
        tip: 'Report question',
        title: 'Report question',
        text: 'Is there something you think wrong with this question? Send us your explanation (with as much detail as possible!).',
        message: 'Message',
        placeholder: 'I would like to report this question beacause...',
        cancel: 'Cancel',
        submit: 'Submit',
        success: {
          title: 'Question reported',
          text: 'Thank you for your feedback!',
          button: 'Ok',
        },
      },
    },
    training: {
      heading: 'Training',
      start: {
        deck: 'Deck',
        due: 'Due',
        new: 'New',
        cards: 'Cards',
        start: 'Start',
        continue: 'Continue',
        reset: 'Reset',
      },
      empty: {
        title: 'Add decks first',
        text: 'You have not added any decks yet.',
        button: 'Add decks',
      },
      later: {
        title: 'Come back later!',
        text: 'You have left {{cards}} cards for today, come back at {{time}}!',
        button: 'Go to dashboard',
      },
      disabled: {
        title: 'Training is disabled',
        text: 'Training is turned off!',
        button: 'Ok',
      },
      afterStudy: {
        title: 'Study first',
        text: 'First you have some studying to do!',
        button: 'Ok',
      },
      done: {
        title: 'Finished!',
        text: 'You have finished the training, you can start an other or come back later.',
        button: 'Ok',
      },
    },
    decks: {
      heading: 'Decks',
      training: 'Training',
      learn: {
        add: 'Add to learn list',
        remove: 'Remove from learn list',
        reset: 'Reset progress',
        edit: 'Edit cards',
      },
      warning: {
        manual: {
          title: 'Custom study',
          text: 'Custom study is not good, do not use it!',
          confirm: 'Confirm',
          cancel: 'Cancel',
          hide: 'Do not show this again',
        },
        add: {
          title: 'Add deck',
          text: 'Are you sure you want to add this deck to your learning list?',
          confirm: 'Confirm',
          cancel: 'Cancel',
        },
        remove: {
          title: 'Remove deck',
          text: 'Are you sure you want to remove this deck from your learning list? You lose all of the progress of this deck!',
          confirm: 'Confirm',
          cancel: 'Cancel',
        },
        reset: {
          title: 'Reset deck',
          text: 'Are you sure you want to reset the progress this deck?',
          confirm: 'Confirm',
          cancel: 'Cancel',
        },
        reject: {
          title: 'Add deck',
          text: 'You have a lot of new cards you have not reviewed yet. Go review them first!',
          confirm: 'OK',
        },
      },
    },
    pdeck: {
      heading: 'Personal deck',
      create: 'Add a new card',
      search: {
        placeholder: 'Search...',
      },
      form: {
        node: 'Node',
        create: 'Create card',
        update: 'Update card',
      },
      empty: {
        title: 'Your deck is empty',
        text: 'You have not added any cards yet, click the button bellow to add a new card.',
        button: 'Add your first card',
      },
      notfound: {
        title: 'No cards found...',
        text: `We could not found any results for "{{query}}"`,
      },
      delete: {
        title: 'Confirm delete!',
        text: 'Are you sure you want to delete this card?',
        confirm: 'Yes, delete it!',
        cancel: 'Cancel',
      },
    },
    learn: {
      heading: 'Learn',
      all: 'all your cards',
      session: 'this session',
      start: {
        deck: 'Deck',
        due: 'Due',
        new: 'New',
        button: 'Start learning',
        progress_button: 'Study progress',
      },
      empty: {
        title: 'Add decks first',
        text: 'You have not added any decks yet. Add a deck to start learning!',
        button: 'Add decks',
      },
      done: {
        title: 'Congratulations!',
        text: 'You have finished for now. Come back tomorrow to learn more.',
        button: 'Go to dashboard',
      },
      good: {
        title: 'Alright, good job!',
        text: 'You finished this session keep going till you finish your whole learning for today',
        button: 'Go to dashboard',
      },
      later: {
        title: 'Come back later!',
        text: 'You have left {{cards}} cards for today, next card due at {{time}}!',
        button: 'Go to dashboard',
      },
      nodue: {
        title: 'Congratulations!',
        text: 'You have nothing to learn for now. Come back tomorrow to learn more.',
        button: 'Go to dashboard',
      },
      study: {
        show: 'Show answer',
        easy: 'Easy',
        good: 'Good',
        hard: 'Hard',
        again: 'Again',
        none: 'None',
        continue: 'Continue',
        leave_confirm: 'Are you sure you want to leave?',
        skip: 'Skip',
      },
      report: {
        tip: 'Report card',
        title: 'Report card',
        text: 'Is there something you think wrong with this card? Send us your explanation (with as much detail as possible!).',
        label: 'Message',
        placeholder: 'I would like to report this card beacause...',
        cancel: 'Cancel',
        submit: 'Submit',
        success: {
          title: 'Card reported',
          text: 'Thank you for your feedback!',
          button: 'Ok',
        },
      },
      delay: {
        tip: 'Delay card',
        title: 'Delay card',
        text: 'How many days do you want to delay this card?',
        submit: 'Delay',
        label: 'Days',
      },
      notes: {
        tip: 'Notes',
        heading: 'Notes',
        placeholder: 'Write your notes about this card here...',
      },
      favorite: {
        mark: 'Like card',
        unmark: 'Unlike card',
      },
      comments: {
        tip: 'Comments',
      },
    },
    goal: {
      heading: 'Study goal',
      invite: {
        subline: {
          invited: 'Invited you to be study partners',
          requested: 'You sent an invite to be study partners',
        },
        buttons: {
          accept: 'Accept',
          decline: 'Decline',
          revoke: 'Revoke',
        },
        alerts: {
          denied: 'Access denied',
          invitee_not_free: 'The invitee is already have a study partner.',
          inviter_not_free: 'The inviter already have a study partner',
          exists: 'Invite already exists!',
          invited: 'The user aready sent you an invite',
          sent: 'Invite sent',
          accepted: 'You and {name} are study partners from now',
          declined: 'Invite successfully declined',
          revoked: 'Invite successfully revoked',
        },
      },
      calendar: {
        title: 'Calendar',
        tabs: {
          history: 'History',
          estimation: 'Estimation',
        },
        tooltip: {
          no_data: 'No data',
          rest: 'Rest day',
          bad: 'Cards: {{ addedCards }} / {{ minAddedCards }}<br />Todo: {{ doneCards }} / {{ todoCards }}<br/>Simulations: {{ finishedSimulations }} / {{ shouldBeSimulations }}<br />Todo: {{ doneSimulations }} / {{ todoSimulations }}',
          good: 'Cards: {{ addedCards }} / {{ minAddedCards }}<br />Todo: {{ doneCards }} / {{ todoCards }}<br/>Simulations: {{ finishedSimulations }} / {{ shouldBeSimulations }}<br />Todo: {{ doneSimulations }} / {{ todoSimulations }}',
          future:
            'Cards should be {{ shouldBeCards }}, est will be {{ estWillBeCards }}, todo {{ todoCards }}<br />Simulations should be {{ shouldBeSimulations }}, done {{ doneSimulations }}, todo {{ todoSimulations }}',
          estimated_future_good:
            'Cards {{ estCards }}, est will do {{ estDoCards }}, avg: {{ avgCards }}<br />Simulations {{ estSimulations }}, todo {{ todoSimulations }}',
          estimated_future_bad:
            'Cards {{ estCards }}, est will do {{ estDoCards }}, avg: {{ avgCards }}, shoulg be avg: {{ needAvgCards }}, diff: {{ diffAvgCards }}<br />Simulations {{ estSimulations }}, todo {{ todoSimulations }}',
          estimated_spare: 'Spare day',
          estimated_over:
            'Cards {{ estCards }}, est will do {{ estDoCards }}, avg: {{ avgCards }}, shoulg be avg: {{ needAvgCards }}, diff: {{ diffAvgCards }}<br />Simulations {{ estSimulations }}, todo {{ todoSimulations }}',
          estimated_finish_before: 'Estimated finish before',
          estimated_finish_after:
            'Estimated finish after, behind days: {{ behindDays }}, avg: {{ avgCards }}, shoulg be avg: {{ needAvgCards }}, diff: {{ diffAvgCards }}',
          estimated_finish: 'Etimated finish',
          finish: 'Finish',
        },
        alert: {
          no_data: 'Not engough data',
          low_study: 'The avg cards per day is too low',
          done: 'Finished',
        },
      },
      partner: {
        heading: 'Study partner',
        breakup: {
          button: 'Break up',
          success: 'You are not study partners anymore',
        },
      },
      dialogs: {
        breakup: {
          title: 'Are you sure',
          text: 'Are you sure you want to break up the study partnership.',
          confirm: 'Confirm',
          cancel: 'Cancel',
        },
      },
      settings: {
        alerts: {
          denied: 'Access denied',
          no_pending: 'You dont have any pending changes',
          has_pending: 'You have pending changes',
          updated: 'Your study config was updated',
          pending: 'Your study config was updated. Waiting for partner to accept your study config',
          accepted: 'You accepted the study config changes',
          declined: 'You declined the study config changes',
          cancelled: 'You cancelled the study config changes',
        },
      },
      team: {
        heading: 'Invite study partner',
        subline: 'Team member',
        invite: 'Invite',
        alone: 'Join to a team to invite someone to study with you',
      },
      edit: {
        heading: 'Study config',
        desc: 'Editing description/instructions',
        startDate: 'Start date',
        endDate: 'End date',
        restDays: 'Rest days',
        simulations: 'Simulations',
        submit: 'Save',
        hasPending: 'Yout cant change your settings while you have pending changes',
      },
      progress: {
        heading: 'Study progress',
        expected: 'Expected',
        added: 'Added',
        behind: 'Behind',
        ahead: 'Ahead',
        left: 'Left',
      },
      simulations: {
        heading: 'Simulation progress',
        expected: 'Expected',
        done: 'Done',
        behind: 'Behind',
        ahead: 'Ahead',
        left: 'Left',
      },
      pendingConfig: {
        user: {
          heading: 'Waiting for partner',
          text: 'Waiting for partner to accept your study config. Start date {{ startDate }}, end date {{ endDate }}, simulations {{ simulations }}.',
          cancel: 'Cancel',
        },
        partner: {
          heading: 'Updated config',
          text: 'Your partner has updated the study config and waiting for you to accept or decline the changes. Start date {{ startDate }}, end date {{ endDate }}, simulations {{ simulations }}.',
          accept: 'Accept',
          decline: 'Decline',
        },
      },
      tabs: {
        personal: 'Personal',
        partner: 'Partner',
        settings: 'Settings',
      },
    },
    comments: {
      card: {
        dialog: {
          title: 'Comments',
        },
        delete: {
          title: 'Delete comment',
          text: 'Are you sure you want to delete this comment?',
          confirm: 'Yes, delete it!',
          cancel: 'Cancel',
          success: 'Comment has been deleted!',
        },
        post: {
          placeholder: 'Write a comment...',
          submit: 'Post',
          success: 'Comment has been posted!',
        },
        edit: {
          placeholder: 'Edit your comment...',
          submit: 'Save',
          cancel: 'Cancel',
          success: 'Comment has been updated!',
        },
        item: {
          createdAt: 'Created at {{date}}',
          editedAt: 'Edited at {{date}}',
          readMore: 'Read more',
        },
        empty: {
          title: 'There’s no comments here yet...',
          text: 'Your thoughts matter, share them here first!',
        },
        tip: {
          edit: 'Edit comment',
          delete: 'Delete comment',
          report: 'Report comment',
          like: 'Like comment',
          dislike: 'Unlike comment',
        },
        loadMore: 'Load more comments',
        readMore: 'Read more',
      },
    },
    search: {
      heading: 'Search',
      query: {
        placeholder: 'Search text...',
      },
      tabs: {
        all: 'All decks',
      },
      decks: {
        label: 'Decks',
      },
      subdecks: {
        label: 'Subdecks',
      },
      node: {
        all: 'Search in all types',
        label: 'Type',
      },
      results: {
        found: '{{count}} cards found',
      },
      fulltext: 'Fulltext',
      keywords: 'Keywords',
      notfound: {
        title: 'No cards found...',
        text: `We could not found any results for "{{query}}"`,
      },
    },
    cardItem: {
      due: 'Due at {{date}}',
      menu: {
        fullscreen: 'Fullscreen',
        notes: 'Notes',
        markFavorite: 'Mark as favorite',
        unmarkFavorite: 'Unmark as favorite',
        comments: 'Comments',
        flip: 'Flip',
      },
    },
    userCards: {
      heading: 'User cards',
      empty: 'No cards found.',
      types: {
        delayed: 'Delayed',
        favorite: 'Favorites',
        note: 'Notes',
        comment: 'My Comments',
        otherscomments: 'Has Comments',
      },
      delayed: {
        edit: {
          title: 'Edit card date',
          text: 'Edit the due date of this card.',
          label: 'New date',
          submit: 'Submit',
        },
      },
    },
    statistics: {
      heading: 'Statistics',
      tabs: {
        simulations: 'Simulations',
        learn: 'Learn',
      },
      filters: {
        '1month': '1 month',
        '3months': '3 months',
        '1year': '1 year',
        all: 'All',
        backlog: 'Backlog',
        time: 'Time',
      },
      kind: {
        new: 'New',
        relearning: 'Relearning',
        learning: 'Learning',
        young: 'Young',
        mature: 'Mature',
        review: 'Review',
      },
      count: {
        days: '{{count}} days',
        cards: '{{count}} cards',
        reviews: '{{count}} reviews',
        reviewsDay: '{{count}} reviews/day',
        cardsDue: '{{count}} cards due',
        cardsDone: '{{done}}/{{due}} ({{percentage}}%) cards, buried {{buried}}, reps {{reps}}',
        minutes: '{{count}} minutes',
        secondsCard: '{{count}} seconds / card',
        minutesDay: '{{count}} minutes/day',
        done: '{{count}} ({{percentage}}%) cards',
      },
      due: {
        title: 'Future Due',
        subtitle: 'The number of reviews due in the future.',
        total: 'Total',
        avg: 'Average',
        today: 'Due today',
        tomorrow: 'Due tomorrow',
      },
      calendar: {
        title: 'Calendar',
      },
      buttons: {
        title: 'Answer Buttons',
        subtitle: 'The number of times you have pressed each button.',
        pressed: '{{count}} times pressed',
      },
      reviews: {
        title: 'Reviews',
        subtitle: 'The number of questions you have answered.',
        studied: 'Days studied',
        studiedInfo: '{{studiedDays}} of {{allDays}} ({{percentage}}%)',
        total: 'Total',
        avgStudied: 'Average for days studied',
        avgPeriod: 'Average over period',
        avgDuration: 'Average duration each card',
        tooltip: {
          reviews: '{{count}} reviews, {{seconds}} seconds/review',
          time: '{{count}} minutes, {{seconds}} seconds/review',
        },
      },
      cards: {
        title: 'Card Counts',
      },
      done: {
        title: 'Cards done',
        subtitle: 'Cards due/done count.',
        due: 'Due',
        done: 'Done',
        buried: 'Buried',
      },
      intervals: {
        title: 'Review Intervals',
        subtitle: 'Delays until reviews are shown again.',
        total: 'Total cards',
        avg: 'Average interval',
      },
      ease: {
        title: 'Card Ease',
        subtitle: 'The lower the ease, the more frequently a card will appear.',
        total: 'Total cards',
        avg: 'Avg ease',
        ease: '{{count}} ease',
      },
      hours: {
        title: 'Hourly Breakdown',
        subtitle: 'Review success rate for each hour of the day.',
        range: '{{from}} ~ {{to}}',
        correct: 'Correct',
        wrong: 'Wrong',
      },
      today: {
        title: 'Today',
        cards: 'Cards',
        reps: 'Reps',
        duration: 'Duration',
        avg: 'Average',
        cardPerSec: '{{seconds}}s/card',
      },
      daily: {
        title: 'Daily study',
        studied: {
          label: 'Studied',
          content: '{{count}} days',
        },
        must: {
          label: 'Must',
          content: '{{count}} days',
        },
        finished: {
          label: 'Finished',
          content: '{{count}} days',
        },
        tooltip: {
          finished:
            'Finished studying; due {{due}}, cards {{done}} ({{percentage}}%), reps {{reps}}, buried {{buried}}',
          not_finished:
            'Studied but not finished; due {{due}}, cards {{done}} ({{percentage}}%), reps {{reps}}, buried {{buried}}',
          not_studied: 'Not studied',
          no_data: 'Not studied and not had to',
        },
      },
      decks: {
        title: 'Decks',
        subtitle: 'Your decks count',
        user: 'Yours',
        site: 'Site avg',
        currently: {
          you_one: 'Currently {{date}} you: <span>{{you}}</span> sub-decks 😁',
          you_other: 'Currently {{date}} you: <span>{{you}}</span> sub-decks 😞',
          site: 'Currently {{date}} site avg: <span>{{site}}</span> sub-decks',
        },
        dialog: {
          title: 'Hide site average',
          text: 'If you do not want to see your bad performance you can hide the site average.',
          confirm: 'Hide it',
          cancel: 'Show it',
        },
      },
    },
    simustats: {
      heading: 'Simulation statistics',
      chart: {
        title: {
          results: 'Results',
          topics: 'Topic',
          simulations: 'Simulation',
        },
        filters: {
          '1month': '1 month',
          '3months': '3 months',
          '1year': '1 year',
          all: 'All',
          '1month_tip': '1 month tip',
          '3months_tip': '3 months tip',
          '1year_tip': '1 year tip',
          all_tip: 'All tip',
        },
        subtitle: 'Your progress with simulations',
        desired: 'Desired level',
        average: 'Average',
        result: 'Result',
        cols: {
          avg: 'Site Average',
          uavg: 'Your Average',
          total: 'Total',
          first: 'First Result',
          reviews: '{{count}} reviews',
        },
      },
      table: {
        simulations: 'Simulations',
        topics: 'Topics',
        title: 'Name',
        percentage: 'Your Avg',
        diff: 'Site Avg',
        sessions: 'Reviews',
        more: 'Show more',
        title_tip: 'Name tip',
        percentage_tip: 'Your Avg tip',
        diff_tip: 'Site Avg tip',
        sessions_tip: 'Reviews tip',
      },
      legends: {
        better: 'Better than Avg.',
        worst: 'Worst than Avg.',
        same: 'Same as Avg.',
        avg: 'Your Avg.',
      },
      dialog: {
        title: 'Hide site average',
        text: 'If you do not want to see your bad performance you can hide the site average.',
        confirm: 'Hide it',
        cancel: 'Show it',
      },
    },
    guides: {
      heading: 'Guides',
    },
    posts: {
      heading: 'Posts',
      readMore: 'Read More',
    },
    badges: {
      daily_study: {
        name: 'Daily study',
        desc: 'Completed daily study',
        tip: 'Test tooltip',
      },
      weekly_study: {
        name: 'Weekly study',
        desc: 'Completed your weekly study',
        tip: 'Test tooltip',
      },
      weekly_study_x3: {
        name: 'Weekly study 3x',
        desc: 'Completed your weekly study 3 times',
        tip: 'Test tooltip',
      },
      weekly_study_x5: {
        name: 'Weekly study 5x',
        desc: 'Completed your weekly study 5 times',
        tip: 'Test tooltip',
      },
      weekly_study_x10: {
        name: 'Weekly study 10x',
        desc: 'Completed your weekly study 10 times',
        tip: 'Test tooltip',
      },
      monthly_study: {
        name: 'Monthly study',
        desc: 'Completed your monthly study',
        tip: 'Test tooltip',
      },
      monthly_study_x3: {
        name: 'Monthly study 3x',
        desc: 'Completed your monthly study 3 times',
        tip: 'Test tooltip',
      },
      monthly_study_x5: {
        name: 'Monthly study 5x',
        desc: 'Completed your monthly study 5 times',
        tip: 'Test tooltip',
      },
      monthly_study_x6: {
        name: 'Monthly study 6x',
        desc: 'Completed your monthly study 6 times',
        tip: 'Test tooltip',
      },
      top_five: {
        name: 'Top Five',
        desc: 'You are in the top five on the leaderboard',
        tip: 'Test tooltip',
      },
      top_three: {
        name: 'Top Three',
        desc: 'You are in the top three on the leaderboard',
        tip: 'Test tooltip',
      },
      number_one: {
        name: 'Number One',
        desc: 'You became the number one in the leaderboard',
        tip: 'Test tooltip',
      },
      added_decks_x5: {
        name: 'Added 5 decks',
        desc: 'Added 5 new decks to your learning stack',
        tip: 'Test tooltip',
      },
      added_decks_x15: {
        name: 'Added 15 decks',
        desc: 'Added 15 new decks to your learning stack',
        tip: 'Test tooltip',
      },
      added_decks_x40: {
        name: 'Added 40 decks',
        desc: 'Added 40 new decks to your learning stack',
        tip: 'Test tooltip',
      },
      study_9_hours: {
        name: 'Study 9 hours',
        desc: 'Studied 9 hours in a day',
        tip: 'Test tooltip',
      },
      study_9_hours_x3: {
        name: 'Study 9 hours 3x',
        desc: 'Studied 9 hours in a day 3 times',
        tip: 'Test tooltip',
      },
      study_9_hours_x5: {
        name: 'Study 9 hours 5x',
        desc: 'Studied 9 hours in a day 5 times',
        tip: 'Test tooltip',
      },
      study_9_hours_x10: {
        name: 'Study 9 hours 10x',
        desc: 'Studied 9 hours in a day 10 times',
        tip: 'Test tooltip',
      },
      study_11_hours: {
        name: 'Study 11 hours',
        desc: 'Studied 11 hours in a day',
        tip: 'Test tooltip',
      },
      study_11_hours_x3: {
        name: 'Study 11 hours 3x',
        desc: 'Studied 11 hours in a day 3 times',
        tip: 'Test tooltip',
      },
      study_11_hours_x5: {
        name: 'Study 11 hours 5x',
        desc: 'Studied 11 hours in a day 5 times',
        tip: 'Test tooltip',
      },
      study_11_hours_x10: {
        name: 'Study 11 hours 10x',
        desc: 'Studied 11 hours in a day 10 times',
        tip: 'Test tooltip',
      },
      study_12_hours: {
        name: 'Study 12 hours',
        desc: 'Studied 12 hours in a day',
        tip: 'Test tooltip',
      },
      study_12_hours_x3: {
        name: 'Study 12 hours 3x',
        desc: 'Studied 12 hours in a day 3 times',
        tip: 'Test tooltip',
      },
      study_12_hours_x5: {
        name: 'Study 12 hours 5x',
        desc: 'Studied 12 hours in a day 5 times',
        tip: 'Test tooltip',
      },
      study_12_hours_x10: {
        name: 'Study 12 hours 10x',
        desc: 'Studied 12 hours in a day 10 times',
        tip: 'Test tooltip',
      },
      avg_decks: {
        name: 'More than avg decks',
        desc: 'You have morethan avg decks',
        tip: 'Test tooltip',
      },
      finished_topic: {
        name: 'Finished topic',
        desc: 'You have finished a topic',
        tip: 'Test tooltip',
      },
      finished_course: {
        name: 'Finished course',
        desc: 'You have finished the course',
        tip: 'Test tooltip',
      },
    },
    arena: {
      heading: 'Learn Together',
      nav: {
        profile: 'Profile',
        leaderboard: 'Leaderboard',
        team: 'Team',
        invites: 'Invites',
        points: 'Point logs',
      },
      celebrate: {
        title: 'Congratulation!',
        text: 'You won a badge named {{badge}}!',
        button: 'Great!',
      },
      logs: {
        added_decks_x: 'Added {{level}} decks',
        daily_cards_x: 'Daily learning cards {{level}}',
        study_time_x: 'Daily sudy time: {{hours}}',
        daily_study: 'Daily finished study',
        study_9_hours: 'Study for 9 hours',
        study_9_hours_x: 'Study for 9 hours {{level}} times',
        study_11_hours: 'Study for 11 hours',
        study_11_hours_x: 'Study for 11 hours {{level}} times',
        study_12_hours: 'Study for 12 hours',
        study_12_hours_x: 'Study for 12 hours {{level}} times',
        monthly_study: 'Finished daily study for 30 days',
        monthly_study_x: 'Finished daily study for 30 days {{level}} times',
        number_one: 'You are the number one on the leaderboard',
        weekly_study: 'Finished daily study for 7 days',
        weekly_study_x: 'Finished daily study for 7 days {{level}} times',
        weekly_streak_x: 'Weekly study streak {{level}}',
        top_three: 'You are in the top 3 on the leaderboard',
        top_five: 'You are in the top 3 on the leaderboard',
        avg_decks: 'You have more decks than the average',
        finished_topic: 'You have finished a topic',
        finished_course: 'You have finished the course',
        more: 'Show more',
        empty: 'You have not earned any points yet.',
      },
      profile: {
        badges: 'Badges',
        points: 'Points',
        team_points: 'Team points',
        level: 'Level {{level}}',
        anonym_on: 'Anonym On',
        anonym_off: 'Anonym Off',
      },
      board: {
        tabs: {
          people: 'Individuals',
          teams: 'Teams',
          members: 'Members',
        },
        people: {
          badges: 'Badges <span>{{count}}</span>',
          invite: 'Invite user',
          unblock: 'Unblock user',
          form: {
            label: 'Message',
            placeholder: '',
            send: 'Send invite',
            cancel: 'Cancel',
            required: 'Message is required',
          },
          alerts: {
            sent: 'Invite sent',
            not_free: 'The user is not free',
            blocked: 'Your team is blocked by the user',
            exists: 'You already sent an invite.',
            denied: 'Access denied.',
          },
          info: {
            blocked: 'This user blocked your teams invites.',
            requested: 'The user already requested to join your team.',
            invited: 'Your team already sent an invite to this user.',
          },
        },
        teams: {
          request: 'Request to join',
          unblock: 'Unblock team',
          form: {
            label: 'Message',
            placeholder: '',
            send: 'Send request',
            cancel: 'Cancel',
            required: 'Message is required',
          },
          alerts: {
            sent: 'Request sent',
            blocked: 'Your are blocked by the team',
            denied: 'Access denied.',
            exists: 'You already sent a request.',
            not_free: 'You are already part of a team.',
          },
          info: {
            blocked: 'The team blocked you.',
            requested: 'You already requested to join',
            invited: 'The team already sent you an invite.',
          },
        },
        members: {
          points: 'Points',
          team_points: 'Team points',
        },
      },
      team: {
        messages: 'Messages',
        details: 'Details',
        settings: 'Settings',
        leave: 'Leave team',
        members: 'Members',
        points: 'Points',
        team_rank: 'Team rank',
        created: 'Created',
        leaving: {
          title: 'Are you sure',
          text: 'Are you sure you want to leave this team? The action is not revesible!',
          confirm: 'Confirm',
          cancel: 'Cancel',
        },
      },
      messages: {
        empty: {
          title: 'No messages',
          text: 'There are no messages yet, be the first...',
        },
        pull: 'Pull down to refresh',
        release: 'Release to refresh',
        deleted: 'Message deleted...',
        denied: 'Access denied.',
      },
      alone: {
        title: 'Teamwork',
        text: 'You can create a team or you can send a request to join to a team from the leaderboard.',
        join: 'Join to a team',
        create: 'Create a team',
        prompt: {
          title: 'Create your team',
          text: 'Enter the name of your team you want to create.',
          label: 'Team name',
          button: 'Create team',
          cancel: 'Cancel',
        },
      },
      invites: {
        block: 'Block team',
        decline: 'Decline',
        accept: 'Accept',
      },
      settings: {
        poster: {
          title: 'Change Your Image',
          description: 'You can drag the picture to crop it differently.',
          upload: 'Upload image',
          change: 'Change image',
          delete: 'Delete image',
          zoom: 'Zoom',
          confirm: 'Confirm',
          cancel: 'Cancel',
          notimg: 'Selected file is not supported. Please upload only .jpeg, .png or .svg file.',
          toobig: 'Selected file is too big. Max size is 5MB.',
          allowed: 'Allowed *.jpeg, *.jpg, *.png, *.gif',
          max: 'max size of 5 MB',
        },
        team: {
          heading: 'Team settings',
          name: 'Team name',
          update: 'Update team',
        },
        members: {
          heading: 'Members',
          prompt: {
            title: 'Are you sure',
            text: 'Are you sure you want to kick out this member? The action is not revesible!',
            confirm: 'Confirm',
            cancel: 'Cancel',
          },
        },
        invites: {
          heading: 'Invites',
          delete: 'Delete',
        },
        requests: {
          heading: 'Requests',
          block: 'Block user',
          decline: 'Decline',
          accept: 'Accept',
        },
        disband: {
          heading: 'Disband team',
          text: 'This action is not reversable...',
          button: 'Disband team',
          prompt: {
            title: 'Are you sure',
            text: 'Are you sure you want to disband this team? The action is not revesible!',
            confirm: 'Confirm',
            cancel: 'Cancel',
          },
        },
        reset: {
          heading: 'Reset team points',
          text: 'You can reset the inside team points so you can compete from the same start...',
          button: 'Reset points',
          prompt: {
            title: 'Are you sure',
            text: 'Are you sure you want to reset team points? The action is not revesible!',
            confirm: 'Confirm',
            cancel: 'Cancel',
          },
        },
      },
    },
    intro: {
      ui: {
        prev: 'Back',
        next: 'Next',
        done: 'Done',
      },
      dashboard: {
        welcome: {
          title: 'Welcome!',
          text: 'Welcome to almogmethod text.',
        },
        learn: {
          title: 'Learn',
          text: 'This is the learn button.',
        },
        search: {
          title: 'Search',
          text: 'This is the search button.',
        },
        simulations: {
          title: 'Simulations',
          text: 'This is the simulations button.',
        },
        arena: {
          title: 'Arena',
          text: 'This is the arena button.',
        },
        decks: {
          title: 'Decks',
          text: 'This is the decks button.',
        },
        statistics: {
          title: 'Statistic',
          text: 'This is the statistics button.',
        },
        posts: {
          title: 'Posts',
          text: 'This is the posts button.',
        },
      },
      search: {
        input: {
          title: 'Input',
          text: 'This is the input field.',
        },
        decks: {
          title: 'Decks',
          text: 'This is the deck selector.',
        },
        mode: {
          title: 'Mode',
          text: 'This is search mode switcher.',
        },
      },
      search_results: {
        full: {
          title: 'Fullscreen',
          text: 'This is the fullscreen button.',
        },
        back: {
          title: 'Back',
          text: 'This is the back button.',
        },
      },
      stats: {
        page: {
          title: 'Statistics',
          text: 'Here you can find your statistics.',
        },
        simulations: {
          title: 'Simulations',
          text: 'Here you can switch to simulations.',
        },
      },
      simu_stats: {
        hide: {
          title: 'Hide avg',
          text: 'Here you can hide site avg.',
        },
      },
      decks: {
        list: {
          title: 'Decks',
          text: 'Here are the available decks.',
        },
        open: {
          title: 'Open',
          text: 'The subdecks are closed by default, you can open it with this button.',
        },
      },
      subdecks: {
        list: {
          title: 'Subdecks',
          text: 'This is the list of the available subdecks.',
        },
        open: {
          title: 'Open',
          text: 'The subdeck id closed by default, you can open it with this button.',
        },
        active: {
          title: 'Active',
          text: 'This icon shows the subdeck is added ot not.',
        },
      },
      add_subdeck: {
        add: {
          title: 'Add subdeck',
          text: 'This is the add button',
        },
      },
      simulations: {
        list: {
          title: 'Simulations',
          text: 'These are the available simulations.',
        },
        results: {
          title: 'Simulation results',
          text: 'Here you can go to your simulation history.',
        },
      },
      simulation_sidebar: {
        details: {
          title: 'Simulation',
          text: 'This is the simulation details, stats.',
        },
      },
      simulation: {
        header: {
          title: 'Simulation header',
          text: 'Here you see the elapsed time and your progress.',
        },
        card: {
          title: 'Question card',
          text: 'This is a question card.',
        },
        topic: {
          title: 'Topic',
          text: 'Here you see the topic of the question.',
        },
        question: {
          title: 'Question',
          text: 'Here you see the question.',
        },
        options: {
          title: 'Answers',
          text: 'Here you see the available answers.',
        },
        answer: {
          title: 'Answer',
          text: 'Here you can select an answer.',
        },
        dot: {
          title: 'Dot thing',
          text: 'This is the dot thingy.',
        },
      },
      simulation_result: {
        result: {
          title: 'Simulation result',
          text: 'Here you see your result.',
        },
        difficulty: {
          title: 'Difficulty stats',
          text: 'Here you see the difficulty stats.',
        },
        finish: {
          title: 'Finish',
          text: 'This is the finish button.',
        },
      },
      learn_start: {
        due: {
          title: 'Decks due',
          text: 'This is what due now.',
        },
        start: {
          title: 'Start',
          text: 'This is the start button.',
        },
      },
      learn_page: {
        notes: {
          title: 'Notes',
          text: 'This is the notes button-',
        },
        report: {
          title: 'Report',
          text: 'This is the report button.',
        },
        close: {
          title: 'Close',
          text: 'This is the close button.',
        },
        show: {
          title: 'Show Answer',
          text: 'This is the show answer button.',
        },
      },
      learn_answer: {
        card: {
          title: 'Card',
          text: 'This is the card...',
        },
        difficulty: {
          title: 'Difficulty',
          text: 'These are the difficulty options.',
        },
      },
      arena_profile: {
        bar: {
          title: 'Profile',
          text: 'This is our profile info.',
        },
        anonym: {
          title: 'Anonym',
          text: 'This is the anonym toggle button',
        },
        badges: {
          title: 'Badges',
          text: 'This is the badges list.',
        },
        leaderboard: {
          title: 'Leaderboard',
          text: 'This is the leaderboard nav button.',
        },
        team: {
          title: 'Team',
          text: 'This is the team nav button.',
        },
        points: {
          title: 'Points',
          text: 'This is the points nav button.',
        },
      },
      arena_players: {
        nav: {
          title: 'Navigation',
          text: 'This is the leaderboard nav.',
        },
        row: {
          title: 'Player',
          text: 'This is a user...',
        },
        name: {
          title: 'Name',
          text: 'This the name and rank of a user',
        },
        progress: {
          title: 'Progress',
          text: 'This is your progress.',
        },
        team: {
          title: 'Team',
          text: 'Here you see the team of a user.',
        },
        points: {
          title: 'Points',
          text: 'Here you see the points of a user.',
        },
      },
      arena_teams: {
        row: {
          title: 'Team',
          text: 'This is a team...',
        },
        name: {
          title: 'Name',
          text: 'This the name and rank of a team',
        },
        progress: {
          title: 'Progress',
          text: 'This is your progress.',
        },
        members: {
          title: 'Members',
          text: 'Here you see the top members of a team.',
        },
        points: {
          title: 'Points',
          text: 'Here you see the points of a team.',
        },
      },
      team_messages: {
        messages: {
          title: 'Messages',
          text: 'Here you can write to your peers.',
        },
      },
    },
    admin: {
      general: {
        error: 'There was an error...',
        all: 'All',
      },
      table: {
        rowsPerPage: 'Rows per page',
        displayedRows: '{{from}}-{{to}} of {{count}}',
      },
      roles: {
        user: 'User',
        staff: 'Staff',
        admin: 'Admin',
      },
      memberships: {
        newbie: 'Newbie',
        pending: 'Pending',
        active: 'Active',
        expired: 'Expired',
        free: 'Free',
      },
      capabilities: {
        manage_users: 'Manage users',
        manage_reports: 'Manage reports',
        manage_leeches: 'Manage leeches',
        manage_decks: 'Manage decks',
        manage_posts: 'Manage posts',
        manage_guides: 'Manage guides',
        manage_questions: 'Manage questions',
        manage_teams: 'Manage teams',
        manage_simulations: 'Manage simulations',
        manage_revlogs: 'Manage study logs',
        manage_site: 'Manage site',
        manage_comments: 'Manage comments',
        manage_question_topics: 'Manage question topics',
        view_questions: 'View questions',
        edit_questions: 'Edit questions',
        delete_questions: 'Delete questions',
        create_questions: 'Create questions',
        edit_own_questions: 'Edit own questions',
        delete_own_questions: 'Delete own questions',
      },
      delete: {
        title: 'Confirm delete!',
        text: 'Are you sure you want to delete this item?',
        confirm: 'Yes, delete it!',
        cancel: 'Cancel',
      },
      qsimdelete: {
        title: 'Confirm delete!',
        text: 'This question is assigned to at least one simulation.<br/>\r\nAre you sure you want to delete it?',
        confirm: 'Yes, delete it!',
        cancel: 'Cancel',
      },
      reset: {
        title: 'Confirm reset!',
        text: 'Are you sure you want to reset this form?',
        confirm: 'Yes, reset it!',
        cancel: 'Cancel',
      },
      notifications: {
        create: {
          title: 'Add notification',
          users: 'Users',
          message: 'Message',
          send: 'Send',
          success: 'Notification sent!',
        }
      },
      sidebar: {
        dashboard: 'Dashboard',
        simulations: 'Simulations',
        users: 'Users',
        decks: 'Decks',
        nodes: 'Nodes',
        import: 'Import',
        export: 'Export',
        convert: 'Convert',
        reports: 'Reports',
        leeches: 'Leeches',
        settings: 'Settings',
        media: 'Media',
        questions: 'Questions',
        guides: 'Guides',
        posts: 'Posts',
        emails: 'Email Templates',
        teams: 'Teams',
        translate: 'Translate',
        revlogs: 'Study log',
        cardComments: 'Card comments',
        orders: 'Orders',
      },
      revlogs: {
        heading: 'Study log',
        filter: {
          card: 'Card ID',
          user: 'User ID',
        },
        table: {
          id: 'ID',
          user_id: 'User ID',
          card_id: 'Card ID',
          button: 'Button',
          duration: 'Duration',
          was_due: 'Was due',
          next_due: 'Next due',
          created: 'Created at',
          version: 'Version',
        },
      },
      users: {
        heading: 'Users',
        create: 'Create user',
        table: {
          id: 'ID',
          name: 'Name',
          image: 'Picture',
          email: 'Email',
          role: 'Role',
          created_at: 'Since',
          can_simulate: 'Simulations',
          simulation_results_count: 'Simulations done',
          practice_simulations_count: 'Practice simulations',
          learns_count: 'Decks',
          points: 'Points',
          team_points: 'Team points',
          is_online: 'Online',
          last_active_at: 'Last active at',
          last_card_at: 'Last card at',
          anonym: 'Anonym',
          fake_name: 'Fake name',
          team: 'Team',
          actions: 'Actions',
          phone: 'Phone',
          notes: 'Notes',
          cards: 'Personal cards',
          authored_questions_count: 'Authored questions',
          membership: 'Membership',
          email_verified_at: 'Email verified at',
        },
        form: {
          name: 'Name',
          email: 'Email',
          password: 'Password',
          role: 'Role',
          enabled: 'Enabled',
          simulations: 'Simulations',
          phone: 'Phone',
          notes: 'Notes',
          update: 'Save changes',
          create: 'Create user',
          delete: 'Delete',
          points: 'Points',
          team_points: 'Team points',
          capabilities: 'Capabilities',
          membership: 'Membership',
          capabilities: {
            label: 'Capabilities',
            groups: {
              general: 'General',
              questions: 'Questions',
            },
          },
          mark_weekend: 'Mark weekend',
          email_verified: 'Email verified',
        },
        points: {
          log: 'Log',
          add: 'Add',
          amount: 'Amount',
          note: 'Note',
          submit: 'Submit',
        },
        search: {
          placeholder: 'Search user',
        },
        menu: {
          simulations: 'Simulations',
          stats: 'Stats',
          decks: 'Decks',
          points: 'Points',
          edit: 'Edit',
          delete: 'Delete',
          impersonate: 'Impersonate',
          progress: 'Progress',
        },
        decks: {
          title: 'Decks',
          none: 'No decks',
          subdeck_info: 'Added at: {{added_at}} | Progress: {{progress}}/{{cards}}',
        },
      },
      teams: {
        heading: 'Teams',
        reset: 'Reset',
        table: {
          id: 'ID',
          name: 'Name',
          image: 'Picture',
          points: 'Points',
          members: 'Members',
          rank: 'Rank',
          created_at: 'Created',
          leader: 'Leader',
          actions: 'Actions',
          admin: 'Admin',
        },
      },
      orders: {
        heading: 'Orders',
        table: {
          id: 'ID',
          user_id: 'User ID',
          user: 'User',
          status: 'Status',
          price: 'Price',
          updated_at: 'Updated at',
          created_at: 'Created at',
        },
        status: {
          created: 'Created',
          pending: 'Pending',
          failed_to_create: 'Failed to create',
          completed: 'Completed',
          failed: 'Failed',
        },
      },
      cardComments: {
        heading: 'Card comments',
        filter: {
          all: 'All',
          deck: 'Deck',
          subdeck: 'Subdeck',
          card: 'Card ID',
          user: 'User ID',
          comment: 'Comment ID',
          orderBy: {
            label: 'Order by',
            options: {
              id: 'ID',
              reputation: 'Reputation',
              created_at: 'Created at',
              updated_at: 'Updated at',
            },
          },
          order: {
            label: 'Order',
            options: {
              desc: 'Descending',
              asc: 'Ascending',
            },
          },
        },
      },
      questions: {
        heading: 'Questions',
        form: {
          heading: 'Question',
          question: 'Question',
          option_1: 'Option 1',
          option_2: 'Option 2',
          option_3: 'Option 3',
          option_4: 'Option 4',
          explanation: 'Explanation',
          topic: 'Topic',
          chapter: 'Chapter',
          difficulty: 'Difficulty',
          update: 'Save changes',
          create: 'Create question',
          reset: 'Reset',
          lock: 'Lock',
          tags: 'Tags',
          type: 'Type',
        },
        filter: {
          topic: 'Topic',
          chapter: 'Chapter',
          difficulty: 'Difficulty',
          all: 'All',
          filter: 'Filter',
          selected: 'Selected',
          not_selected: 'Not selected',
          not_used: 'Not used',
          used: 'Used',
          type: 'Type',
          add_all: 'Add all',
          remove_all: 'Remove all',
          simulation: 'Simulation',
          tags: 'Tags',
          sortBy: {
            label: 'Sort by',
            options: {
              id: 'ID',
              created_at: 'Created at',
              updated_at: 'Updated at',
            },
          },
          author: 'Author',
        },
        tabs: {
          assign: 'Assign',
          filter: 'Filter',
        },
        assign: {
          no_change: 'No change',
          submit: 'Submit',
          tag_mode: {
            label: 'Tag mode',
            options: {
              add: 'Add',
              remove: 'Remove',
              sync: 'Sync',
            },
          },
          selection: 'Selection',
        },
        added: 'Question added successfully!',
        types: {
          all: 'All',
          simu: 'Simulation',
          practice: 'Practice',
        },
      },
      topics: {
        heading: 'Topics',
        create: 'Create topic',
        form: {
          name: 'Name',
          color: 'Color',
          priority: 'Priority',
          update: 'Update',
          save: 'Create',
        },
      },
      simulations: {
        heading: 'Simulations',
        create: 'Create simulation',
        status: {
          published: 'Published',
          draft: 'Draft',
          archived: 'Archived',
        },
        shuffle: {
          topics: 'Shuffle topics',
          options: 'Shuffle options',
          questions: 'Shuffle questions',
          global: 'Global shuffle',
        },
        table: {
          title: 'Title',
          status: 'Status',
          score: 'Score',
          difficulty: 'Difficulty',
          questions: 'Questions',
          created: 'Created at',
          updated: 'Updated at',
          actions: 'Actions',
          priority: 'Priority',
          folder: 'Folder',
        },
        form: {
          icon: 'Icon',
          title: 'Title',
          description: 'Description',
          status: 'Status',
          update: 'Save changes',
          create: 'Create simulation',
          priority: 'Priority',
          submit_each_question: 'Submit each question',
          folder: 'Folder',
          no_folder: 'No folder',
          color: 'Color',
          colors: {
            default: 'Default',
            deck1: 'Deck 1',
            deck2: 'Deck 2',
            deck3: 'Deck 3',
            deck4: 'Deck 4',
          },
        },
        menu: {
          results: 'Results',
          edit: 'Edit',
          questions: 'Question',
          delete: 'Delete',
        },
      },
      simulation: {
        heading: 'Simulation',
        create: 'Create question',
        allTopic: 'All Topic',
        question: {
          question: 'Question',
          option_1: 'Option 1',
          option_2: 'Option 2',
          option_3: 'Option 3',
          option_4: 'Option 4',
          explanation: 'Explanation',
          topic: 'Topic',
          update: 'Save changes',
          create: 'Create question',
        },
      },
      simufolders: {
        heading: 'Folders',
        form: {
          icon: 'Icon',
          title: 'Title',
          description: 'Description',
          parent: 'Parent folder',
          update: 'Save changes',
          save: 'Create simulation',
          priority: 'Priority',
          no_parent: 'No parent',
          color: 'Color',
          colors: {
            default: 'Default',
            deck1: 'Deck 1',
            deck2: 'Deck 2',
            deck3: 'Deck 3',
            deck4: 'Deck 4',
          },
        },
        create: 'Create folder',
      },
      simuresults: {
        heading: 'Simulation results',
        button: 'Summary',
        table: {
          name: 'Name',
          simulation: 'Simulation',
          questions: 'Questions',
          correct: 'Correct',
          wrong: 'Wrong',
          result: 'Result',
          created_at: 'Created',
          actions: 'Actions',
          duration: 'Duration',
          minutes: '{{minutes}}min',
          score: 'Score',
        },
        drawer: {
          summary: 'Summary',
          topics: 'Topics',
          result: 'Result',
        },
      },
      nodes: {
        heading: 'Nodes',
        create: 'Create node',
        table: {
          name: 'Name',
          label: 'Label',
          fields: 'Fields',
          templates: 'Templates',
          cards: 'Cards',
          models: 'Models',
          updated: 'Updated',
          actions: 'Actions',
        },
        form: {
          name: 'Name',
          label: 'Label',
          update: 'Save changes',
          create: 'Create node',
          delete: 'Delete',
          public: 'Public',
        },
        fields: {
          heading: 'Fields',
          name: 'Name',
          label: 'Label',
          priority: 'Priority',
          update: 'Save changes',
          create: 'Create field',
        },
        templates: {
          heading: 'Templates',
          front: 'Front',
          name: 'Name',
          back: 'Back',
          styles: 'Styles',
          update: 'Save changes',
          create: 'Create template',
        },
        menu: {
          fields: 'Fields',
          templates: 'Templates',
          edit: 'Edit',
          delete: 'Delete',
        },
      },
      decks: {
        move_to: 'Move to',
        label_decks: 'Decks',
        success: 'data successfully sent',
        error: 'data successfully sent',
        move_to_cards: 'move those cards to ',
        heading: 'Decks',
        subdecks: 'Sub decks',
        create: 'Create deck',
        table: {
          title: 'Title',
          cards: 'Cards',
          models: 'Models',
          subdecks: 'Subdecks',
          priority: 'Priority',
          main: 'Main',
          created: 'Created at',
          updated: 'Updated at',
          actions: 'Actions',
        },
        form: {
          title: 'Title',
          decription: 'Description',
          priority: 'Priority',
          main: 'Main',
          update: 'Save changes',
          create: 'Create deck',
        },
        menu: {
          subdecks: 'Subdecks',
          edit: 'Edit',
          delete: 'Delete',
        },
      },
      subdecks: {
        heading: 'Subdecks',
        create: 'Create subdeck',
        table: {
          title: 'Title',
          cards: 'Cards',
          models: 'Models',
          priority: 'Priority',
          created: 'Created at',
          updated: 'Updated at',
          actions: 'Actions',
          public: 'Public',
        },
        form: {
          title: 'Title',
          decription: 'Description',
          deck: 'Deck',
          public: 'Public',
          priority: 'Priority',
          update: 'Save changes',
          create: 'Create subdeck',
        },
        menu: {
          cards: 'Cards',
          edit: 'Edit',
          delete: 'Delete',
        },
      },
      cards: {
        heading: 'Cards',
        create: 'Create card',
        form: {
          node: 'Node',
          update: 'Save changes',
          create: 'Create card',
        },
        search: {
          placeholder: 'Search...',
        },
      },
      import: {
        heading: 'Import',
        choose: 'Choose file',
        invalid: 'The file is invalid',
        models: 'Models',
        templates: 'Templates',
        fields: 'Fields',
        decks: 'Decks',
        notes: 'Notes',
        media: 'Media',
        deck: 'Choose deck',
        start: 'Start importing data',
        import: 'Import',
        replace: 'Replace',
        next: 'Next',
        node: 'Node',
        steps: {
          file: 'Select file',
          data: 'Select data',
          models: 'Replace models',
          deck: 'Select deck',
          import: 'Import',
        },
      },
      export: {
        heading: 'Export',
        name: 'Name',
        submit: 'Export',
      },
      convert: {
        heading: 'Convert',
        upload: 'Upload file',
        almogToAnki: 'Almog to Anki',
        ankiToAlmog: 'Anki to Almog',
      },
      reports: {
        heading: 'Reports',
        delete_resolved: 'Delete resolved',
        types: {
          card: 'Card',
          question: 'Question',
          card_comment: 'Card comment',
        },
        table: {
          user_id: 'User ID',
          name: 'Name',
          email: 'Email',
          item_type: 'Type',
          item_id: 'Item ID',
          author_name: 'Author',
          created_at: 'Created at',
          resolved_at: 'Resolved at',
          actions: 'Action',
        },
        menu: {
          message: 'Message',
          view: 'View',
          delete: 'Delete',
          resolve: 'Resolve',
        },
        filter: {
          all: 'All',
          type: {
            label: 'Type',
            options: {
              card: 'Card',
              question: 'Question',
              card_comment: 'Card comment',
            },
          },
          resolved: {
            label: 'Resolved',
            options: {
              yes: 'Yes',
              no: 'No',
            },
          },
          author: 'Author',
        },
      },
      leeches: {
        heading: 'Leeches',
        table: {
          id: 'ID',
          user: 'User',
          card_id: 'Card ID',
          lapses: 'Lapses',
          leeches: 'Leeches',
          created_at: 'Created at',
          resolved_at: 'Resolved at',
        },
        filter: {
          user: 'User id',
          card: 'Card id',
        },
      },
      media: {
        heading: 'Media',
        trash: 'Trash',
        table: {
          id: 'ID',
          filename: 'Filename',
          mime: 'Mime',
          type: 'Used as',
          filesize: 'Size',
          user: 'Uploader',
          created_at: 'Created at',
          delete: 'Delete',
        },
        deleted: 'Deleted {{count}} files!',
      },
      settings: {
        heading: 'Settings',
        save: 'Save',
        reset: 'Reset',
        saved: 'Settings saved',
        arena: {
          heading: 'Arena',
          reset: 'Reset',
          alert: {
            title: 'Confirm reset!',
            text: 'Are you sure you want to reset the arena?',
            confirm: 'Yes, reset it!',
            cancel: 'Cancel',
          },
        },
        register: {
          heading: 'Registration',
          maxUsers: 'Max users',
        },
        goal: {
          heading: 'Goals',
          startDate: 'Start date',
          endDate: 'End date',
          countCards: 'Count cards on the site',
          cardsAmount: 'Custom card amiunt',
          simulations: 'Simulations',
        },
        pdeck: {
          heading: 'Personal deck',
          maxCards: 'Max cards',
        },
        simu: {
          heading: 'Simulations',
          difficulties: 'Difficulties',
          practiceLimit: 'Practice limit',
        },
        training: {
          heading: 'Training',
          enabled: 'Enabled',
          onlyAfterStudy: 'Only after study',
          onlyFullDecks: 'Only full decks',
        },
        shop: {
          heading: 'Shop',
          productName: 'Product name',
          renewProductName: 'Renew product name',
          price: 'Price',
          renewPrice: 'Renew price',
        },
        course: {
          heading: 'Course',
          startDate: 'Start date',
          endDate: 'End date',
        },
        learn: {
          general: {
            heading: 'General',
            collapseTime: 'Collapse time',
            newLimit: 'New card limit',
            queueLimit: 'Queue limit',
            maxFactor: 'Maximum factor',
            dailySkips: 'Daily skips',
          },
          new: {
            heading: 'New',
            spread: {
              label: 'Spread',
              distribute: 'Distribute',
              first: 'First',
              last: 'Last',
            },
            bury: 'Bury siblings',
            delays: 'Learning steps',
            initialFactor: 'Initial factor',
            easyIvl: 'Easy interval',
            goodIvl: 'Good interval',
          },
          rev: {
            heading: 'Review',
            bury: 'Bury siblings',
            ivlFactor: 'Interval modifier',
            maxIvl: 'Max interval',
            hardFactor: 'Hard factor',
            easyFactor: 'Easy bonus',
          },
          lapse: {
            heading: 'Lapses',
            delays: 'Relearning steps',
            minIvl: 'Minimum Interval',
            mult: 'New interval',
            leeches: 'Leehes (...steps, repeat)',
          },
        },
      },
      guides: {
        heading: 'Guides',
        create: 'Create guide',
        form: {
          title: 'Title',
          icon: 'Icon',
          description: 'Description',
          body: 'Body',
          public: 'Public',
          priority: 'Priority',
          update: 'Save changes',
          create: 'Create guide',
        },
        table: {
          icon: 'Icon',
          title: 'Title',
          description: 'Description',
          public: 'Public',
          priority: 'Priority',
          created_at: 'Created At',
          updated_at: 'Updated At',
          actions: 'Actions',
        },
      },
      posts: {
        heading: 'Posts',
        create: 'Create post',
        form: {
          title: 'Title',
          description: 'Description',
          body: 'Body',
          public: 'Public',
          update: 'Save changes',
          create: 'Create post',
        },
        table: {
          title: 'Title',
          description: 'Description',
          public: 'Public',
          created_at: 'Created At',
          updated_at: 'Updated At',
          published_at: 'Published At',
          actions: 'Actions',
        },
      },
      emails: {
        heading: 'Emails',
        create: 'Create email',
        form: {
          name: 'Name',
          subject: 'Subject',
          body: 'Body',
          default: 'Default',
          users: 'Users',
          update: 'Save changes',
          create: 'Create post',
        },
        table: {
          name: 'Name',
          default: 'Default',
          created_at: 'Created At',
          updated_at: 'Updated At',
          users: 'Users',
          actions: 'Actions',
        },
      },
      translate: {
        heading: 'Translate',
        reset: 'Reset',
        rewrite: 'Rewrite',
        download: 'Download',
      },
    },
  },
};

export default en;
